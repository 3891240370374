import { mapGetters, mapState } from 'vuex'
import PriceRules from 'theme/mixins/blocks/PriceRules'

export default {
  name: 'EarnedPoints',
  mixins: [
    PriceRules
  ],
  computed: {
    ...mapState({
      currentUser: (state) => state.user.current
    }),
    ...mapGetters({
      rewards: 'loyalty/getCustomerAccounts',
      cartItems: 'cart/getCartItems',
      getBrands: 'brands/getBrands'
    })
  },
  methods: {
    async getCurrentBalance (brandId) {
      let brandCode = this.getBrandCode(brandId)
      let estimate = await this.getEarnedPoints()

      // console.log('Current Balance Data', brandId, brandCode, estimate)

      if (!brandCode || !estimate[brandCode]) {
        let currentAccount = this.rewards.find(account => account.bank.code === brandCode) || {}

        return parseFloat(((currentAccount.balance || 0) / 100).toString()).toFixed(2)
      }

      return (estimate[brandCode] || {}).balance - (estimate[brandCode] || {}).spending
    },
    getBrandCode (id) {
      const brands = this.getBrands || []
      const brand = brands.find(brand => brand.id === id)

      if (!brand) return false

      if (brand.parent_id) {
        return this.getBrandCode(brand.parent_id)
      }
      return brand.code
    },
    async getRewards () {
      let rewards = {}
      const brands = this.getBrands || []
      const locations = this.currentUser ? this.currentUser.extension_attributes.locations_data : []

      this.rewards.forEach(reward => {
        if (reward.bank.inactive) {
          return
        }

        let brandId = (brands.find(x => x.code === reward.bank.code) || {}).id
        let hasPermission = locations.find(location => location.brand_id === brandId && location.status === true)

        rewards[reward.bank.code] = {
          id: reward.bank.id,
          account_id: reward.id,
          name: reward.bank.name,
          // look up brand id
          brand_id: brandId,
          code: reward.bank.code,
          balance: reward.balance_in_dollars,
          earnedPoints: reward.earnedPoints ? reward.earnedPoints : 0,
          color: reward.bank.hex_color ? reward.bank.hex_color : '#ffffff',
          increase: false,
          subBrands: {},
          subBrandIds: [],
          hasPermission: hasPermission,
          hasSubBrandPermission: false
        }
      })

      brands.forEach(brand => {
        if (!brand.parent_id) return

        let parent = brands.find(x => x.id === brand.parent_id)

        if (!parent) return

        if (rewards[parent.code]) {
          let hasPermission = locations.find(location => location.brand_id === brand.id && location.status === true)

          if (hasPermission) {
            rewards[parent.code].hasSubBrandPermission = true
          }

          rewards[parent.code].subBrandIds.push(brand.id)
          rewards[parent.code].subBrands[brand.code] = {
            brand_id: brand.id,
            name: brand.name,
            code: brand.code,
            hasPermission: hasPermission
          }
        }
      })

      if (!this.isAccountMenu) {
        const earnedPoints = await this.getEarnedPoints()

        for (let key in earnedPoints) {
          if (!earnedPoints[key]['earning']) continue

          rewards[key]['earnedPoints'] = earnedPoints[key]['earning']
          rewards[key]['increase'] = true
        }
      }

      return rewards
    },
    async getEarnedPoints () {
      let items = []

      this.cartItems.forEach(item => {
        let currentAccount = this.rewards.filter(account => account.bank.code === this.getBrandCode(item.brand_id))[0]

        if (!currentAccount || !item.point_can_earn || (!!item.point_redemption_price && !!item.point_can_earn)) return {}

        let bankCode = currentAccount.bank.code

        let rewardSpendQty = ((item.product_option || {}).extension_attributes || {}).qty_using_points || 0
        let calculatedQty = item.qty - rewardSpendQty

        if (calculatedQty > 0) {
          items.push({
            sku: item.sku,
            account: bankCode,
            bank: currentAccount.bank,
            type: String(item.point_redemption_type || 'unknown'),
            qty: calculatedQty,
            price: this.getRulePrice(item) || item.price,
            earning_multiplier: item.point_earning_multiplier || 1,
            redemption: !!item.point_redemption_price,
            can_earn: !!item.point_can_earn
          })
        }

        if (rewardSpendQty > 0) {
          items.push({
            sku: item.sku,
            account: bankCode,
            bank: currentAccount.bank,
            type: String(item.point_redemption_type || 'unknown'),
            qty: rewardSpendQty,
            price: this.getRulePrice(item) || item.price,
            earning_multiplier: item.point_earning_multiplier || 1,
            redemption: true,
            can_earn: false
          })
        }
      })

      if (items.length === 0) {
        return {}
      }

      const earnedPoints = await this.$store.dispatch('loyalty/loyaltyEstimatePoints', { items: items })

      return earnedPoints.accounts
    }
  }
}
